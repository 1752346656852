import React, { useEffect } from "react";
import logo from "../img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faPerson, faTicket, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

function LogoHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token")

  useEffect(() => {
    if (!token) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      navigate(`/login?${currentUrl.searchParams}`);
    }
  }, [token, location, navigate]);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem('token', '');

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          <li>
              <a href="/soiree2024/dashboard">
              <FontAwesomeIcon icon={faChartBar} />&nbsp;
                <span className="shop-menu-ttl">Dashboard</span>
              </a>
            </li>
            <li>
              <a href="/soiree2024/attendees">
              <FontAwesomeIcon icon={faPerson} />&nbsp;
                <span className="shop-menu-ttl">Register Tickets</span>
              </a>
            </li>
            <li>
              <a href="/soiree2024/raffle">
              <FontAwesomeIcon icon={faTicketAlt} />&nbsp;
                <span className="shop-menu-ttl">Raffle</span>
              </a>
            </li>
            <li>
              <a href="/soiree2024/vouchers">
              <FontAwesomeIcon icon={faTicket} />&nbsp;
                <span className="shop-menu-ttl">Vouchers</span>
              </a>
            </li>

            {token && <>
              <li>
              <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#b0b1b3"}} />&nbsp;
                <span className="shop-menu-ttl"></span>
            </li>
            <li className="stylization">
              <a onClick={handleLogout} style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faSignOut} />&nbsp;
                <span className="shop-menu-ttl">Log Out</span>
              </a>
            </li>            
            </>}
          </ul>


        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader;